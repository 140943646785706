import React, { useEffect, useState } from 'react';
import Footer from "../components/Footer";
import HeaderTeams from "../components/headers/HeaderTeams";
import Team from "../components/Team";
import ModalImage from "../components/modals/ModalImage";

const ourTeamData = {
    '#ignacio': {
        photo: 'images/team_ignacio.png',
        userName: 'IGNACIO VIAL',
        location: 'SEVILLA, SPAIN',
        link: 'https://www.linkedin.com/in/ignaciovial/',
        bgClass: 'bg-ignacio',
        description: "Ignacio brings global founder experience to NRP having founded a VCbacked company out of Chile " +
            "and Miami that scaled to 5 international markets and integrated 1000's of companies throughout the " +
            "Americas. His experience in product deployment and managing operations across dozens of countries has " +
            "resulted in a truly global perspective of the venture-backed startup ecosystem",
        images: [
            {
                id: 1,
                src: process.env.REACT_APP_ASSETS_URL + 'images/our-team/ignacio/image-1.jpg',
            },
            {
                id: 2,
                src: process.env.REACT_APP_ASSETS_URL + 'images/our-team/ignacio/image-2.jpg',
            },
            {
                id: 3,
                src: process.env.REACT_APP_ASSETS_URL + 'images/our-team/ignacio/image-3.jpg',
            },
        ]
    },
    '#alex': {
        photo: 'images/team_alex.png',
        userName: 'ALEX CROSBY',
        location: 'AUSTIN, TX USA',
        link: 'https://www.linkedin.com/in/crosbyah/',
        bgClass: 'bg-alex',
        description: "Alex specializes in strategic financial management and fundraising strategy for venture-backed " +
            "companies. He actively mentors and advises C-Suites at Enterprise, SMB and Startup companies across the " +
            "Americas and Europe. These companies have gone on to raise over $350M. Alex is active in local and " +
            "international ecosystems, having held workshops and speaking engagements for universities, conferences " +
            "like SXSW and CES, and globally recognized accelerators throughout the US, Europe, and Latin America",
        images: [
            {
                id: 1,
                src: process.env.REACT_APP_ASSETS_URL + 'images/our-team/alex/image-1.jpg',
            },
            {
                id: 2,
                src: process.env.REACT_APP_ASSETS_URL + 'images/our-team/alex/image-2.jpg',
            },
            {
                id: 3,
                src: process.env.REACT_APP_ASSETS_URL + 'images/our-team/alex/image-3.jpg',
            },
        ]
    },
    '#christian': {
        photo: 'images/team_christian.png',
        userName: 'CHRISTIAN BEHN',
        location: 'AMSTERDAM',
        link: 'https://www.linkedin.com/in/christian-behn-5a8221/',
        bgClass: 'bg-christian',
        description: "Christian brings significant startup CFO capabilities and credibility to highgrowth entities " +
            "to nlock that next stage of scaling. He works with founders and management on three different " +
            "continents to structure and position themselves for the growth they are targeting before the " +
            "challenges of runway, burn rates and revenue models force companies to be between a rock and a hard " +
            "place. In 2010 he joined Siine Ltd as a late cofounder and co-led 2 rounds of funding. He is a " +
            "Techstars alum and AllStar Mentor",
        images: [
            {
                id: 1,
                src: process.env.REACT_APP_ASSETS_URL + 'images/our-team/christian/image-1.jpg',
            },
            {
                id: 2,
                src: process.env.REACT_APP_ASSETS_URL + 'images/our-team/christian/image-2.jpg',
            },
            {
                id: 3,
                src: process.env.REACT_APP_ASSETS_URL + 'images/our-team/christian/image-3.jpg',
            },
        ]
    },
    '#george': {
        photo: 'images/team_george.png',
        userName: 'GEORGE KARAM',
        location: 'NEW YORK, US',
        link: 'https://www.linkedin.com/in/georgebarroskaram/',
        bgClass: 'bg-george',
        description: "George brings decades of experience as both a successful founder, and a M&A advisor to " +
            "C-Suite executives of small to mid-market cap companies across the globe in transactions ranging " +
            "from $10M-1.5B. Guiding them through capital raises and change of control situations. He specializes " +
            "in determining market timing, buy-sell side transaction structure and optimizing enterprise value for " +
            "founders taking the next step in their entrepreneurial journey. George is an MBA alumni of MIT Sloan " +
            "School of Management and holds the CFA and CAIA designations",
        images: [
            {
                id: 1,
                src: process.env.REACT_APP_ASSETS_URL + 'images/our-team/george/image-1.jpg',
            },
            {
                id: 2,
                src: process.env.REACT_APP_ASSETS_URL + 'images/our-team/george/image-2.jpg',
            },
            {
                id: 3,
                src: process.env.REACT_APP_ASSETS_URL + 'images/our-team/george/image-3.jpg',
            },
        ]
    },
}
ourTeamData[''] = {...ourTeamData["#ignacio"]}

const OurTeamContainer = ({ location }) => {

    const [show, setShow] = useState(false);
    const [imgSrc, setImgSrc] = useState('');

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [location]);

    return (
        <>
            <HeaderTeams
                menu="ourTeam"
                userName={ourTeamData[`${location.hash}`].userName}
                location={ourTeamData[`${location.hash}`].location}
                photo={ourTeamData[`${location.hash}`].photo}
                bgClass={ourTeamData[`${location.hash}`].bgClass}
                link={ourTeamData[`${location.hash}`].link}
            />

            <main role="main">

                <div className="container-fluid text-dark pt-4 pt-md-5 pb-5">
                    <div className="row">
                        <div className="container">
                            <div className="row">
                                <div className="col-12">
                                    <p className="font-size-4 font-weight-light">
                                        {ourTeamData[`${location.hash}`].description}
                                    </p>
                                </div>
                            </div>

                            <div className="row pt-5">
                                {ourTeamData[`${location.hash}`].images.map(d => (
                                    <div
                                        className="col-12 col-md-4 col-md text-center mb-3 cursor-pointer"
                                        key={d.id}
                                        onClick={() => {
                                            setImgSrc(d.src);
                                            setShow(true);
                                        }}
                                    >
                                        <div className="row m-0">
                                            <div
                                                className="col-12 bg-our-team-single border rounded-full-10px"
                                                style={{
                                                    backgroundImage: "url('" + d.src + "')"
                                                }}
                                            />
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>

            </main>

            <div className="container">
                <div className="row">
                    <div className="col-12 border-top-separator">
                        <Team color="dark"/>
                    </div>
                </div>
            </div>

            <Footer />

            <ModalImage show={show} handleClose={() => setShow(false)} imgSrc={imgSrc}/>
        </>
    );
};

export default OurTeamContainer;
