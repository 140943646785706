import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import {ToastContainer} from "react-toastify";

import './styles/sass/main.scss';
import 'react-toastify/dist/ReactToastify.css';
import 'react-image-crop/lib/ReactCrop.scss';
import 'react-confirm-alert/src/react-confirm-alert.css';

import App from './App';
import * as serviceWorker from './serviceWorker';

ReactDOM.render(
    <Router>
        <App />
        <ToastContainer
            position="top-right"
            autoClose={5000}
            hideProgressBar
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover />
    </Router>, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
