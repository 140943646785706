import React from 'react';

const PitchSection = () => {
    return (
        <div className="container-fluid pt-4 pt-md-5">
            <div className="row">
                <div className="container">
                    <div className="row">
                        <div className="col-6">
                            <p className="font-size-5">We help founders revamp their fundraising narrative</p>
                            <p className="font-weight-light">
                                Oftentimes founders fall into a ‘salesman like’ approach to presenting their company.
                                At NRP we believe in developing genuine connections with potential VCs and work
                                tirelessly to help our founders truly understand the intricacies of a successful
                                fundraising narrative
                            </p>

                            <p className="font-size-5">Raising money can be stressful</p>
                            <p className="font-weight-light">
                                We come in and help founders quickly refine their key value props from an objective
                                outside perspective and revamp their fundraising narrative to create a compelling
                                'why now' for investors that highlights key differentiators and separates them from
                                the rest of the companies raising today
                            </p>
                        </div>
                        <div className="col-6">
                            <p className="font-size-5">We look at situation from another perspective</p>
                            <p className="font-weight-light">
                                Founders are in the trenches and it can be hard for them to step back and take a 10
                                thousand foot objective view of their business. Founders love each one of them equally
                                because they make the machine work. The problem is that the rest of the world only
                                cares about the founder’s child who is the star starting quarterback (or midfielder
                                for our EU friends)
                            </p>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-12">
                            <p className="text-right font-weight-light mt-5 font-size-4">
                                There are two ways to do your pitch deck. <strong>Go to NextRound Partners if you
                                want it done right!</strong>
                            </p>
                        </div>
                        <div className="col-12">
                            <div className="row">
                                <div className="col-3 border-bottom border-primary ml-auto mr-3"/>
                            </div>
                            <div className="row">
                                <div className="col-12 ml-auto mt-2">
                                    <p className="font-size-4 font-weight-light text-right text-primary">
                                        Sashank P.,
                                        <br/>
                                        Founder & CEO
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PitchSection;
