import React from 'react';

import Footer from "../components/Footer";
import HeaderSection from "../components/headers/HeaderSection";
import GlobalFootprint from "../components/about/GlobalFootprint";
import MeetPartners from "../components/about/MeetPartners";
import JoinUs from "../components/about/JoinUs";

const AboutContainer = () => {
    return (
        <>
            <HeaderSection menu="about" title="OUR MISSION" subtitle="To be the help that we wish we had during our previous ventures"/>

            <main role="main">
                <GlobalFootprint />
                <MeetPartners />
                <JoinUs />
            </main>

            <Footer />
        </>
    );
};

export default AboutContainer;
