import React from 'react';

const VcSection = () => {
    return (
        <div className="container-fluid pt-4 pt-md-5">
            <div className="row">
                <div className="container">
                    <div className="row">
                        <div className="col-6">
                            <p className="font-size-5">Capitalize on momentum for your portfolio</p>
                            <p className="font-weight-light">
                                We partner with you and work with each of your portfolio companies post investment to
                                get them on track with a model and best practices while their options and
                                resources are highest.
                            </p>
                        </div>
                        <div className="col-6">
                            <p className="font-size-5">Increase your return on investment</p>
                            <p className="font-weight-light">
                                We reinforce your priorities and at the same time save time for you & the fund's
                                partners. So you can focus on other high impact areas.
                            </p>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-12">
                            <p className="text-right font-weight-light mt-5 font-size-4">
                                 The service provided was tailored for our business and the end <strong>results speak
                                for themselves</strong>
                            </p>
                        </div>
                        <div className="col-12">
                            <div className="row">
                                <div className="col-3 border-bottom border-primary ml-auto mr-3"/>
                            </div>
                            <div className="row">
                                <div className="col-12 ml-auto mt-2">
                                    <p className="font-size-4 font-weight-light text-right text-primary">
                                        Steven Le,
                                        <br/>
                                        Founder & CEO
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default VcSection;
