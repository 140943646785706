import React from 'react';

const ModelingSection = () => {
    return (
        <div className="container-fluid pt-4 pt-md-5">
            <div className="row">
                <div className="container">
                    <div className="row">
                        <div className="col-6">
                            <p className="font-size-5">We know exactly how to do it</p>
                            <p className="font-weight-light">
                                While Modeling can help you determine what targets to hit, what decisions to make,
                                what people to hire, how to deploy resources and build a fundraising strategy and many
                                more, we help you elaborate that exact model and possible future scenarios.
                            </p>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-12">
                            <p className="text-right font-weight-light mt-5 font-size-4">
                                NextRound Partners helped our company analyze our pricing scenarios and helped us build
                                out clean pricing models to help our team easily structure deals. Team members have the
                                capability to understand a complex situation, visualize the opportunity, and execute
                                on the solution
                            </p>
                        </div>
                        <div className="col-12">
                            <div className="row">
                                <div className="col-3 border-bottom border-primary ml-auto mr-3"/>
                            </div>
                            <div className="row">
                                <div className="col-12 ml-auto mt-2">
                                    <p className="font-size-4 font-weight-light text-right text-primary">
                                        Heath B.,
                                        <br/>
                                        Founder & CEO
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ModelingSection;
