import React from 'react';
import Team from "../Team";

const MeetPartners = () => {
    return (
        <div className="container-fluid pt-4 pt-md-6">
            <div className="row">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <p className="font-size-6 font-size-md-9 font-weight-bold">
                                MEET OUR PARTNERS
                            </p>
                            <p className="font-weight-light text-dark mt-2">
                                NextRound Partners is a group of global entrepreneurs and subject matter experts
                                working in collaboration with organizations like Endeavor, Techstars, and other
                                global ecosystem players to help founders pave their own way in the ecosystem while
                                implementing innovative approaches and getting off the beaten path.
                            </p>
                            <p className="font-weight-light text-dark mt-2">
                                Each member brings their own unique skill sets, perspectives and approaches to the
                                business world
                            </p>
                        </div>
                    </div>

                    <Team color="dark"/>
                </div>
            </div>
        </div>
    );
};

export default MeetPartners;
