import React from 'react';
import Footer from "../components/Footer";
import {Link, withRouter} from "react-router-dom";

const PrivacyPolicyContainer = () => {
    return (
        <>
            <header>
                <div className="container pt-4">
                    <div className="row">
                        <div className="col-12">
                            <Link to='/'>
                                <img src={ process.env.REACT_APP_ASSETS_URL + 'images/nextroundpartners-footer.png'} alt="logo" width="150"/>
                            </Link>
                        </div>
                    </div>
                </div>
            </header>
            <main role="main">
                <div className="container-fluid text-dark pt-5">
                    <div className="row">
                        <div className="container">
                            <div className="row">
                                <div className="col-12">
                                    <h1 className="text-primary font-size-9">
                                        PRIVACY POLICY & GENERAL <br/>TERMS AND CONDITIONS
                                    </h1>
                                    <p className="font-size-4 pt-2">Last updated 25 february 2021</p>
                                </div>
                                <div className="col-12 mt-4">
                                    {/* eslint-disable-next-line react/jsx-no-target-blank */}
                                    <p>Thank you for choosing to be part of our community at Crosby Modeling LLC, doing business as NextRound Partners. We are committed to protecting your personal information and your right to privacy. If you have any questions or concerns about our policy, or our practices with regards to your personal information, please contact us at <a className="text-dark text-decoration-underline" target="_blank" href="mailto:hello@nextroundpartners.com">hello@nextroundpartners.com</a>.</p>
                                    {/* eslint-disable-next-line react/jsx-no-target-blank */}
                                    <p>When you visit our website <a className="text-dark text-decoration-underline" target="_blank" href="http://www.nextroundpartners.com">http://www.nextroundpartners.com</a>, and use our services, you trust us with your personal information. We take your privacy very seriously. In this privacy policy, we seek to explain to you in the clearest way possible what information we collect, how we use it and what rights you have in relation to it. We hope you take some time to read through it carefully, as it is important. If there are any terms in this privacy policy that you do not agree with, please discontinue use of our Sites and our services.</p>
                                    {/* eslint-disable-next-line react/jsx-no-target-blank */}
                                    <p>This privacy policy applies to all information collected through our website (such as <a className="text-dark text-decoration-underline" target="_blank" href="http://www.nextroundpartners.com">http://www.nextroundpartners.com</a>), and/or any related services, sales, marketing or events (we refer to them collectively in this privacy policy as the "Services"). Please read this privacy policy carefully as it will help you make informed decisions about sharing your personal information with us.</p>
                                    <p className="text-primary font-weight-bold pt-3">
                                        TABLE OF CONTENTS
                                    </p>
                                    <p>
                                        <ol className="pl-4">
                                            <li>WHAT INFORMATION DO WE COLLECT?</li>
                                            <li>HOW DO WE USE YOUR INFORMATION?</li>
                                            <li>WILL YOUR INFORMATION BE SHARED WITH ANYONE?</li>
                                            <li>WHO WILL YOUR INFORMATION BE SHARED WITH?</li>
                                            <li>DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?</li>
                                            <li>HOW DO WE HANDLE YOUR SOCIAL LOGINS?</li>
                                            <li>IS YOUR INFORMATION TRANSFERRED INTERNATIONALLY?</li>
                                            <li>HOW LONG DO WE KEEP YOUR INFORMATION?</li>
                                            <li>HOW DO WE KEEP YOUR INFORMATION SAFE?</li>
                                            <li>DO WE COLLECT INFORMATION FROM MINORS?</li>
                                            <li>WHAT ARE YOUR PRIVACY RIGHTS?</li>
                                            <li>DATA BREACH</li>
                                            <li>CONTROLS FOR DO-NOT-TRACK FEATURES</li>
                                            <li>DO CALIFORNIA RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?</li>
                                            <li>DO WE MAKE UPDATES TO THIS POLICY?</li>
                                            <li>HOW CAN YOU CONTACT US ABOUT THIS POLICY?</li>
                                        </ol>
                                    </p>
                                    <p className="text-primary font-weight-bold pt-3">1. WHAT INFORMATION DO WE COLLECT?</p>
                                    <p>Personal information you disclose to us</p>
                                    <p className="font-italic"><strong>In Short</strong>:  We collect personal information that you provide to us.</p>
                                    <p>We collect personal information that you voluntarily provide to us when registering at the Services expressing an interest in obtaining information about us or our products and services, when participating in activities on the Services or otherwise contacting us.</p>
                                    <p>The personal information that we collect depends on the context of your interactions with us and the Services, the choices you make and the products and features you use. The personal information we collect can include the following:</p>
                                    <p><strong>Publicly Available Personal Information.</strong> We collect first name, maiden name, last name, and nickname; business email; email addresses; business phone number; phone numbers; social media; and other similar data.</p>
                                    <p><strong>Social Media Login Data.</strong> We may provide you with the option to register using social media account details, like your Facebook, Twitter or other social media account. If you choose to register in this way, we will collect the Information described in the section called "HOW DO WE HANDLE YOUR SOCIAL LOGINS" below.</p>
                                    <p>All personal information that you provide to us must be true, complete and accurate, and you must notify us of any changes to such personal information.</p>
                                    <p className="font-weight-bold pt-3">Information automatically collected</p>
                                    <p className="font-italic"><strong>In Short:</strong> Some information — such as IP address and/or browser and device characteristics — is collected automatically when you visit our Services.</p>
                                    <p>We automatically collect certain information when you visit, use or navigate the Services. This information does not reveal your specific identity (like your name or contact information) but may include device and usage information, such as your IP address, browser and device characteristics, operating system, language preferences, referring URLs, device name, country, location, information about how and when you use our Services and other technical information. This information is primarily needed to maintain the security and operation of our Services, and for our internal analytics and reporting purposes.</p>
                                    <p>Like many businesses, we also collect information through cookies and similar technologies. Online Identifiers. We collect devices; applications; tools and protocols, such as IP (Internet Protocol) addresses; cookie identifiers, or others such as the ones used for analytics and marketing; Radio Frequency Identification (RFID) tags; device's geolocation; and other similar data.</p>
                                    <p className="text-primary font-weight-bold pt-3">2. HOW DO WE USE YOUR INFORMATION?</p>
                                    <p className="font-italic"><strong>In Short:</strong> We process your information for purposes based on legitimate business interests, the fulfillment of our contract with you, compliance with our legal obligations, and/or your consent.</p>
                                    <p>We use personal information collected via our Services for a variety of business purposes described below. We process your personal information for these purposes in reliance on our legitimate business interests, in order to enter into or perform a contract with you, with your consent, and/or for compliance with our legal obligations. We indicate the specific processing grounds we rely on next to each purpose listed below.</p>
                                    <p>We use the information we collect or receive:</p>
                                    <p><strong>To send you marketing and promotional communications.</strong> We and/or our third party marketing partners may use the personal information you send to us for our marketing purposes, if this is in accordance with your marketing preferences. You can opt-out of our marketing emails at any time (see the "WHAT ARE YOUR PRIVACY RIGHTS" below).</p>
                                    <p><strong>Fulfill and manage your orders.</strong> We may use your information to fulfill and manage your orders, payments, returns, and exchanges made through the Services.</p>
                                    <p><strong>To post testimonials.</strong> We post testimonials on our Services that may contain personal information. Prior to posting a testimonial, we will obtain your consent to use your name and testimonial. If you wish to update, or delete your testimonial, please contact us at ignacio@nextroundpartners.com and be sure to include your name, testimonial location, and contact information.</p>
                                    <p><strong>Deliver targeted advertising to you.</strong> We may use your information to develop and display content and advertising (and work with third parties who do so) tailored to your interests and/or location and to measure its effectiveness.</p>
                                    <p><strong>Request Feedback.</strong> We may use your information to request feedback and to contact you about your use of our Services.</p>
                                    <p><strong>To protect our Services.</strong> We may use your information as part of our efforts to keep our Services safe and secure (for example, for fraud monitoring and prevention).</p>
                                    <p className="font-weight-bold">To enforce our terms, conditions and policies for Business Purposes, Legal Reasons and Contractual.</p>
                                    <p><strong>To respond to legal requests and prevent harm.</strong> If we receive a subpoena or other legal request, we may need to inspect the data we hold to determine how to respond.</p>
                                    <p><strong>To deliver services to the user.</strong> We may use your information to provide you with the requested service.</p>
                                    <p><strong>To respond to user inquiries/offer support to users.</strong> We may use your information to respond to your inquiries and solve any potential issues you might have with the use of our Services.</p>
                                    <p><strong>For other Business Purposes.</strong> We may use your information for other Business Purposes, such as data analysis, identifying usage trends, determining the effectiveness of our promotional campaigns and to evaluate and improve our Services, products, marketing and your experience. We may use and store this information in aggregated and anonymized form so that it is not associated with individual end users and does not include personal information. We will not use identifiable personal information without your consent.</p>
                                    <p className="text-primary font-weight-bold pt-3">3. WILL YOUR INFORMATION BE SHARED WITH ANYONE?</p>
                                    <p className="font-italic"><strong>In Short:</strong>  We only share information with your consent, to comply with laws, to provide you with services, to protect your rights, or to fulfill business obligations.</p>
                                    <p>We may process or share data based on the following legal basis:</p>
                                    <p><strong>Consent:</strong> We may process your data if you have given us specific consent to use your personal information in a specific purpose.</p>
                                    <p><strong>Legitimate Interests:</strong> We may process your data when it is reasonably necessary to achieve our legitimate business interests.</p>
                                    <p><strong>Performance of a Contract:</strong> Where we have entered into a contract with you, we may process your personal information to fulfill the terms of our contract.</p>
                                    <p><strong>Legal Obligations:</strong> We may disclose your information where we are legally required to do so in order to comply with applicable law, governmental requests, a judicial proceeding, court order, or legal process, such as in response to a court order or a subpoena (including in response to public authorities to meet national security or law enforcement requirements).</p>
                                    <p><strong>Vital Interests:</strong> We may disclose your information where we believe it is necessary to investigate, prevent, or take action regarding potential violations of our policies, suspected fraud, situations involving potential threats to the safety of any person and illegal activities, or as evidence in litigation in which we are involved.</p>
                                    <p>More specifically, we may need to process your data or share your personal information in the following situations:</p>
                                    <p><strong>Vendors, Consultants and Other Third-Party Service Providers.</strong> We may share your data with third party vendors, service providers, contractors or agents who perform services for us or on our behalf and require access to such information to do that work. Examples include: payment processing, data analysis, email delivery, hosting services, customer service and marketing efforts. We may allow selected third parties to use tracking technology on the Services, which will enable them to collect data about how you interact with the Services over time. This information may be used to, among other things, analyze and track data, determine the popularity of certain content and better understand online activity. Unless described in this Policy, we do not share, sell, rent or trade any of your information with third parties for their promotional purposes.</p>
                                    <p><strong>Business Transfers.</strong> We may share or transfer your information in connection with, or during negotiations of, any merger, sale of company assets, financing, or acquisition of all or a portion of our business to another company.</p>
                                    <p className="text-primary font-weight-bold pt-3">4. WHO WILL YOUR INFORMATION BE SHARED WITH?</p>
                                    <p className="font-italic"><strong>In Short:</strong> We only share information with the following third parties.</p>
                                    <p>We only share and disclose your information with the following third parties. We have categorized each party so that you may be easily understand the purpose of our data collection and processing practices. If we have processed your data based on your consent and you wish to revoke your consent, please contact us.</p>
                                    <p className="font-weight-bold mb-0">Allow Users to Connect to their Third-Party Accounts</p>
                                    <p>Google account</p>
                                    <p className="font-weight-bold mb-0">Communicate and Chat with Users</p>
                                    <p>Zendesk Chat</p>
                                    <p className="font-weight-bold mb-0">Data Backup and Security</p>
                                    <p>Google Drive Backup</p>
                                    <p className="font-weight-bold mb-0">Retargeting Platforms</p>
                                    <p>Google Analytics Remarketing, LinkedIn Website Retargeting and Salesforce DMP</p>
                                    <p className="font-weight-bold mb-0">Social Media Sharing and Advertising</p>
                                    <p>LinkedIn advertising and LinkedIn social plugins</p>
                                    <p className="font-weight-bold mb-0">User Account Registration and Authentication</p>
                                    <p>Google Sign-In</p>
                                    <p className="font-weight-bold mb-0">Web and Mobile Analytics</p>
                                    <p>Google Analytics, Google Tag Manager, Google Ads and Google Analytics for Firebase</p>
                                    <p className="font-weight-bold mb-0">Website Hosting</p>
                                    <p>Squarespace</p>
                                    <p className="text-primary font-weight-bold pt-3">5. DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?</p>
                                    <p className="font-italic"><strong>In Short:</strong> We may use cookies and other tracking technologies to collect and store your information.</p>
                                    <p>We may use cookies and similar tracking technologies (like web beacons and pixels) to access or store information. Specific information about how we use such technologies and how you can refuse certain cookies is set out in our Cookie Policy.</p>
                                    <p className="text-primary font-weight-bold pt-3">6. HOW DO WE HANDLE YOUR SOCIAL LOGINS?</p>
                                    <p className="font-italic"><strong>In Short:</strong> If you choose to register or log in to our services using a social media account, we may have access to certain information about you.</p>
                                    <p>Our Services offer you the ability to register and login using your third party social media account details (like your Facebook or Twitter logins). Where you choose to do this, we will receive certain profile information about you from your social media provider. The profile Information we receive may vary depending on the social media provider concerned, but will often include your name, e-mail address, friends list, profile picture as well as other information you choose to make public.</p>
                                    <p>We will use the information we receive only for the purposes that are described in this privacy policy or that are otherwise made clear to you on the Services. Please note that we do not control, and are not responsible for, other uses of your personal information by your third party social media provider. We recommend that you review their privacy policy to understand how they collect, use and share your personal information, and how you can set your privacy preferences on their sites and apps.</p>
                                    <p className="text-primary font-weight-bold pt-3">7. IS YOUR INFORMATION TRANSFERRED INTERNATIONALLY?</p>
                                    <p className="font-italic"><strong>In Short:</strong> We may transfer, store, and process your information in countries other than your own.</p>
                                    <p>Our servers are located in. If you are accessing our Services from outside, please be aware that your information may be transferred to, stored, and processed by us in our facilities and by those third parties with whom we may share your personal information (see "WILL YOUR INFORMATION BE SHARED WITH ANYONE?" above), in and other countries.</p>
                                    <p>If you are a resident in the European Economic Area, then these countries may not have data protection or other laws as comprehensive as those in your country. We will however take all necessary measures to protect your personal information in accordance with this privacy policy and applicable law.</p>
                                    <p>EU-U.S. Privacy Shield Framework:</p>
                                    {/* eslint-disable-next-line react/jsx-no-target-blank */}
                                    <p>In particular Company complies with the EU-U.S. Privacy Shield Framework as set forth by the U.S. Department of Commerce regarding the collection, use, and retention of personal information transferred from the European Union to the United States and has certified its compliance with it. As such, Company is committed to subjecting all personal information received from European Union (EU) member countries, in reliance on the Privacy Shield Framework, to the Framework’s applicable Principles. To learn more about the Privacy Shield Framework, visit the <a className="text-dark text-decoration-underline" target="_blank" href="https://www.privacyshield.gov/list">U.S. Department of Commerce’s Privacy Shield List</a>.</p>
                                    <p>Company is responsible for the processing of personal information it receives, under the Privacy Shield Framework, and subsequently transfers to a third party acting as an agent on its behalf.</p>
                                    <p>With respect to personal information received or transferred pursuant to the Privacy Shield Framework, Company is subject to the regulatory enforcement powers of the U.S. FTC. In certain situations, we may be required to disclose personal information in response to lawful requests by public authorities, including to meet national security or law enforcement requirements.</p>
                                    <p className="text-primary font-weight-bold pt-3">8. HOW LONG DO WE KEEP YOUR INFORMATION?</p>
                                    <p className="font-italic"><strong>In Short:</strong> We keep your information for as long as necessary to fulfill the purposes outlined in this privacy policy unless otherwise required by law.</p>
                                    <p>We will only keep your personal information for as long as it is necessary for the purposes set out in this privacy policy, unless a longer retention period is required or permitted by law (such as tax, accounting or other legal requirements). No purpose in this policy will require us keeping your personal information for longer than 2 years past the termination of the user's account.</p>
                                    <p>When we have no ongoing legitimate business need to process your personal information, we will either delete or anonymize it, or, if this is not possible (for example, because your personal information has been stored in backup archives), then we will securely store your personal information and isolate it from any further processing until deletion is possible.</p>
                                    <p className="text-primary font-weight-bold pt-3">9. HOW DO WE KEEP YOUR INFORMATION SAFE?</p>
                                    <p className="font-italic"><strong>In Short:</strong> We aim to protect your personal information through a system of organizational and technical security measures.</p>
                                    <p>We have implemented appropriate technical and organizational security measures designed to protect the security of any personal information we process. However, please also remember that we cannot guarantee that the internet itself is 100% secure. Although we will do our best to protect your personal information, transmission of personal information to and from our Services is at your own risk. You should only access the services within a secure environment.</p>
                                    <p className="text-primary font-weight-bold pt-3">10. DO WE COLLECT INFORMATION FROM MINORS?</p>
                                    <p className="font-italic"><strong>In Short:</strong> We do not knowingly collect data from or market to children under 18 years of age.</p>
                                    <p>We do not knowingly solicit data from or market to children under 18 years of age. By using the Services, you represent that you are at least 18 or that you are the parent or guardian of such a minor and consent to such minor dependent’s use of the Services. If we learn that personal information from users less than 18 years of age has been collected, we will deactivate the account and take reasonable measures to promptly delete such data from our records. If you become aware of any data we have collected from children under age 18, please contact us at ignacio@nextroundpartners.com.</p>
                                    <p className="text-primary font-weight-bold pt-3">11. WHAT ARE YOUR PRIVACY RIGHTS?</p>
                                    <p className="font-italic"><strong>In Short:</strong> In some regions, such as the European Economic Area, you have rights that allow you greater access to and control over your personal information. You may review, change, or terminate your account at any time.</p>
                                    <p>In some regions (like the European Economic Area), you have certain rights under applicable data protection laws. These may include the right (i) to request access and obtain a copy of your personal information, (ii) to request rectification or erasure; (iii) to restrict the processing of your personal information; and (iv) if applicable, to data portability. In certain circumstances, you may also have the right to object to the processing of your personal information. To make such a request, please use the contact details provided below. We will consider and act upon any request in accordance with applicable data protection laws.</p>
                                    <p>If we are relying on your consent to process your personal information, you have the right to withdraw your consent at any time. Please note however that this will not affect the lawfulness of the processing before its withdrawal.</p>
                                    {/* eslint-disable-next-line react/jsx-no-target-blank */}
                                    <p>If you are resident in the European Economic Area and you believe we are unlawfully processing your personal information, you also have the right to complain to your local data protection supervisory authority. You can find their contact details here: <a className="text-dark text-decoration-underline" target="_blank" href="https://ec.europa.eu/newsroom/article29/item-detail.cfm?item_id=612080">http://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm</a>.</p>
                                    <p>If you have questions or comments about your privacy rights, you may email us at</p>
                                    {/* eslint-disable-next-line react/jsx-no-target-blank */}
                                    <p><a className="text-dark text-decoration-underline" target="_blank" href="mailto:hello@nextroundpartners.com">hello@nextroundpartners.com</a>.</p>
                                    <p className="font-weight-bold pt-3">Account Information</p>
                                    <p>If you would at any time like to review or change the information in your account or terminate your account, you can:</p>
                                    <p className="font-weight-bold">Contact us using the contact information provided.</p>
                                    <p>Upon your request to terminate your account, we will deactivate or delete your account and information from our active databases. However, some information may be retained in our files to prevent fraud, troubleshoot problems, assist with any investigations, enforce our Terms of Use and/or comply with legal requirements.</p>
                                    {/* eslint-disable-next-line react/jsx-no-target-blank */}
                                    <p><strong className="text-decoration-underline">Cookies and similar technologies:</strong> Most Web browsers are set to accept cookies by default. If you prefer, you can usually choose to set your browser to remove cookies and to reject cookies. If you choose to remove cookies or reject cookies, this could affect certain features or services of our Services. To opt-out of interest-based advertising by advertisers on our Services visit <a className="text-dark text-decoration-underline" target="_blank" href="https://optout.aboutads.info/?c=2&lang=EN">http://www.aboutads.info/choices/</a>.</p>
                                    <p><strong className="text-decoration-underline">Opting out of email marketing:</strong> You can unsubscribe from our marketing email list at any time by clicking on the unsubscribe link in the emails that we send or by contacting us using the details provided below. You will then be removed from the marketing email list – however, we will still need to send you service-related emails that are necessary for the administration and use of your account. To otherwise opt-out, you may:</p>
                                    <p>- Note your preferences when you register an account with the site.</p>
                                    <p>- Contact us using the contact information provided.</p>
                                    <p className="text-primary font-weight-bold pt-3">12. DATA BREACH</p>
                                    <p>A privacy breach occurs when there is unauthorized access to or collection, use, disclosure or disposal of personal information. You will be notified about data breaches when Crosby Modeling LLC believes you are likely to be at risk or serious harm. For example, a data breach may be likely to result in serious financial harm or harm to your mental or physical well-being. In the event that Crosby Modeling LLC becomes aware of a security breach which has resulted or may result in unauthorized access, use or disclosure of personal information Crosby Modeling LLC will promptly investigate the matter and notify the applicable Supervisory Authority not later than 72 hours after having become aware of it, unless the personal data breach is unlikely to result in a risk to the rights and freedoms of natural persons.</p>
                                    <p className="text-primary font-weight-bold pt-3">13. CONTROLS FOR DO-NOT-TRACK FEATURES</p>
                                    <p>Most web browsers and some mobile operating systems and mobile applications include a Do-Not-Track (“DNT”) feature or setting you can activate to signal your privacy preference not to have data about your online browsing activities monitored and collected. No uniform technology standard for recognizing and implementing DNT signals has been finalized. As such, we do not currently respond to DNT browser signals or any other mechanism that automatically communicates your choice not to be tracked online. If a standard for online tracking is adopted that we must follow in the future, we will inform you about that practice in a revised version of this privacy policy.</p>
                                    <p className="text-primary font-weight-bold pt-3">14. DO CALIFORNIA RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?</p>
                                    <p className="font-italic"><strong>In Short:</strong> Yes, if you are a resident of California, you are granted specific rights regarding access to your personal information.</p>
                                    <p>California Civil Code Section 1798.83, also known as the “Shine The Light” law, permits our users who are California residents to request and obtain from us, once a year and free of charge, information about categories of personal information (if any) we disclosed to third parties for direct marketing purposes and the names and addresses of all third parties with which we shared personal information in the immediately preceding calendar year. If you are a California resident and would like to make such a request, please submit your request in writing to us using the contact information provided below.</p>
                                    <p>If you are under 18 years of age, reside in California, and have a registered account with the Services, you have the right to request removal of unwanted data that you publicly post on the Services. To request removal of such data, please contact us using the contact information provided below, and include the email address associated with your account and a statement that you reside in California. We will make sure the data is not publicly displayed on the Services, but please be aware that the data may not be completely or comprehensively removed from our systems.</p>
                                    <p className="text-primary font-weight-bold pt-3">15. DO WE MAKE UPDATES TO THIS POLICY?</p>
                                    <p className="font-italic"><strong>In Short:</strong> Yes, we will update this policy as necessary to stay compliant with relevant laws.</p>
                                    <p>We may update this privacy policy from time to time. The updated version will be indicated by an updated “Revised” date and the updated version will be effective as soon as it is accessible. If we make material changes to this privacy policy, we may notify you either by prominently posting a notice of such changes or by directly sending you a notification. We encourage you to review this privacy policy frequently to be informed of how we are protecting your information.</p>
                                    <p className="text-primary font-weight-bold pt-3">16. HOW CAN YOU CONTACT US ABOUT THIS POLICY?</p>
                                    <p>If you have questions or comments about this policy, you may email us at ignacio@nextroundpartners.com or by post to:</p>
                                    <p className="mb-0">Crosby Modeling LLC</p>
                                    <p className="mb-0">220 S. Congress Ave</p>
                                    <p className="mb-0">Ste #010</p>
                                    <p className="mb-0">Austin, TX 78704</p>
                                    <p>United States</p>
                                    <p className="font-weight-bold pt-3">HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM YOU?</p>
                                    {/* eslint-disable-next-line react/jsx-no-target-blank */}
                                    <p>Based on the laws of some countries, you may have the right to request access to the personal information we collect from you, change that information, or delete it in some circumstances. To request to review, update, or delete your personal information, please visit: <a className="text-dark text-decoration-underline" target="_blank" href="http://www.nextroundpartners.com">http://www.nextroundpartners.com</a>. We will respond to your request within 30 days.</p>
                                    {/* eslint-disable-next-line react/jsx-no-target-blank */}
                                    <p>This privacy policy was created using <a className="text-dark text-decoration-underline" target="_blank" href="https://termly.io/products/privacy-policy-generator/?ftseo">Termly’s Privacy Policy Generator</a>.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>

            <Footer />
        </>
    );
};

export default withRouter(PrivacyPolicyContainer);
