import React, { useState, useLayoutEffect } from 'react';
import {Link, withRouter} from "react-router-dom";
import ModalMenu from "../modals/ModalMenu";

const HeaderNav = ({ menu, location }) => {
    const [show, setShow] = useState(false);
    const [classNameToNavbar, setClassNameToNavbar] = useState('');

    useLayoutEffect(() => {
        window.addEventListener('scroll', changeClassNameToNavbar)
    }, []);

    const changeClassNameToNavbar = () => {
        if (window.scrollY > 300) {
            setClassNameToNavbar('with-bg');
        } else {
            setClassNameToNavbar('');
        }
    }

    return (
        <div className={"navbar-fixed " + classNameToNavbar}>
            <div className="container py-3">
                <div className="row">
                    <div className="col col-md-3">
                        <Link to='/'>
                            <img src={ process.env.REACT_APP_ASSETS_URL + 'images/logo.png'} alt="logo" width="150"/>
                        </Link>
                    </div>
                    <div className="col-auto ml-auto d-none d-md-flex align-items-center header-menu">
                        <Link className={"link " + (location.hash === '#about' ? 'active' : '')} to="/#about">About</Link>
                        <Link className={"link " + (menu === 'services' || location.hash === '#services' ? 'active' : '')} to="/#services">Services</Link>
                        <Link className={"link " + (menu === 'about' ? 'active' : '')} to="/about">Our Team</Link>
                        <Link className={"link " + (location.hash === '#contact-us' ? 'active' : '')} to="/#contact-us">Contact Us</Link>
                    </div>
                    <div className="col-auto d-flex d-md-none" onClick={() => setShow(true)}>
                        <img
                            src={process.env.REACT_APP_ASSETS_URL + 'images/menu-icon.svg'}
                            alt=""
                        />
                    </div>
                </div>

                <ModalMenu
                    show={show}
                    handleClose={() => setShow(false)}
                    menu={menu}
                />
            </div>
        </div>
    );
};

export default withRouter(HeaderNav);
