import React from 'react';
import { Modal } from 'react-bootstrap';
import {Link, withRouter} from "react-router-dom";

const ModalMenu = ({ show, handleClose, menu, location }) => {
    return (
        <>
            <Modal
                size={"lg"}
                show={show}
                onHide={handleClose}
                enforceFocus={false}
                dialogClassName="modal-dialog-centered px-4"
                className="modal-menu"
            >
                <Modal.Body className="px-4">
                    <div className="row">
                        <div className="col-12 d-flex flex-column py-3">
                            <Link
                                className={"btn btn-light rounded-full-10px py-2 mb-4 " + (location.hash === '#about' ? 'font-weight-bold' : 'text-dark')}
                                to="/#about"
                                onClick={handleClose}
                            >
                                About
                            </Link>
                            <Link
                                className={"btn btn-light rounded-full-10px py-2 mb-4 " + (menu === 'services' || location.hash === '#services' ? 'font-weight-bold' : 'text-dark')}
                                to="/#services"
                                onClick={handleClose}
                            >
                                Services</Link>
                            <Link
                                className={"btn btn-light rounded-full-10px py-2 mb-4 " + (menu === 'about' ? 'font-weight-bold' : 'text-dark')}
                                to="/about"
                                onClick={handleClose}
                            >
                                Our Team</Link>
                            <Link
                                className={"btn btn-light rounded-full-10px py-2 " + (location.hash === '#contact-us' ? 'font-weight-bold' : 'text-dark')}
                                to="/#contact-us"
                                onClick={handleClose}
                            >
                                Contact Us</Link>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
};

export default withRouter(ModalMenu);
