export const validate403AndReturnJson = (data) => {
    if (data.status === 403) {
        window.location.reload();
    }

    if (data.status === 204) {
        return Promise.resolve({
            data: {},
            status: data.status,
        });
    } else {
        return data.json().then(dataJson => {
            return Promise.resolve({
                data: dataJson,
                status: data.status,
            });
        });
    }
};

export const fetchAPI = (url, requestOptions) => {
    return fetch(url, requestOptions).then(data => validate403AndReturnJson(data));
}

export const sendMail = payload => {
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(payload)
    };
    const url = `https://sheltered-dusk-70106.herokuapp.com/send-mail`;
    return fetchAPI(url, requestOptions);
};
