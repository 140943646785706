import React from 'react';
import HeaderNav from "./HeaderNav";
import {Link, withRouter} from "react-router-dom";

const MainHeader = ({ history }) => {
    return (
        <header className="bg-main">
            <HeaderNav />

            <div className="container full-height-home d-flex flex-column align-items-center justify-content-center position-relative">
                <div className="row">
                    <div className="col-12">
                        <p className="text-white font-size-5 font-size-md-10 text-center">
                            GET YOUR NEXT ROUND CLOSED
                        </p>
                    </div>
                </div>
                <div className="row m-0">
                    <div className="cookies-container mx-3 mx-md-0">
                        <div className="col-12 col-md-10 px-3 cookies-container__col">
                            <div className="row">
                                <div className="col d-flex align-items-center text-center">
                                    <span>
                                        We use cookies to ensure that we give you the best experience on our website.
                                        If you continue to use this site we will assume that you are happy with it.
                                        For further information see <Link to={'/privacy-policy'} className="text-dark font-weight-bold text-decoration-underline">our privacy policy</Link>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </header>
    );
};

export default withRouter(MainHeader);
