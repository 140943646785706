import React, { useState } from 'react';
import ModalForm from "../modals/ModalForm";

const JoinUs = () => {
    const [show, setShow] = useState(false);

    return (
        <div className="container-fluid text-dark pt-4 mb-5">
            <div className="row">
                <div className="container">
                    <div className="row">
                        <div className="col-12 col-md-8">
                            <p className="font-size-3 font-weight-light">
                                We are always looking to expand our network and work with cool people that have unique
                                views of the world
                            </p>
                            <p className="font-size-3 font-weight-bold font-italic">
                                If you like working with entrepreneurs on a global scale reach out and let’s talk
                            </p>
                        </div>
                        <div className="col d-flex align-items-center justify-content-center mt-4 mt-md-0">
                            <button className="btn btn-primary font-size-4 py-3 px-5" onClick={() => setShow(true)}>
                                REACH OUT
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <ModalForm show={show} handleClose={() => setShow(false)}/>
        </div>
    );
};

export default JoinUs;
