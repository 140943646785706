import React, {useLayoutEffect, useState} from 'react';
import FractionalSection from "../sections/FractionalSection";
import FundraisingSection from "../sections/FundraisingSection";
import ModelingSection from "../sections/ModelingSection";
import VcSection from "../sections/VCSection";

const Services = () => {
    const [step, setStep] = useState('1');
    const [innerWidth, setInnerWidth] = useState(0);

    useLayoutEffect(() => {
        const updateSize = () => setInnerWidth(window.innerWidth);

        window.addEventListener('resize', updateSize);
        updateSize();

        return () => window.removeEventListener('resize', updateSize);
    }, []);

    return (
        <div className="container-fluid py-4 py-md-5">
            <div className="row">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <p className="font-size-6 font-size-md-9 font-weight-bold text-primary">
                                HOW WE HELP
                            </p>
                            <p className="font-weight-light text-dark mt-2">
                                We fine-tune our services to each founder’s needs by keeping it tactical and actionable
                                but also responsive to the often fluid state of the market and the changes they're
                                navigating
                            </p>
                        </div>
                    </div>

                    <div className="row mt-3">
                        <div className="col-12">
                            <ul className={"nav " + (innerWidth < 800 ? 'nav-pills' : 'nav-tabs')}>
                                <li className="nav-item cursor-pointer" onClick={() => setStep('1')}>
                                    <span className={"nav-link " + (step === '1' ? 'active text-primary font-weight-bold' : '')} aria-current="page">Fractional CFO</span>
                                </li>
                                <li className="nav-item cursor-pointer" onClick={() => setStep('2')}>
                                    <span className={"nav-link " + (step === '2' ? 'active text-primary font-weight-bold' : '')}>Fundraising Strategy</span>
                                </li>
                                <li className="nav-item cursor-pointer" onClick={() => setStep('3')}>
                                    <span className={"nav-link " + (step === '3' ? 'active text-primary font-weight-bold' : '')}>Modeling & Scenario Analysis</span>
                                </li>
                                <li className="nav-item cursor-pointer" onClick={() => setStep('4')}>
                                    <span className={"nav-link " + (step === '4' ? 'active text-primary font-weight-bold' : '')}>VC Portfolio Support</span>
                                </li>
                            </ul>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-12">
                            {step === '1' && (
                                <FractionalSection />
                            )}
                            {step === '2' && (
                                <FundraisingSection />
                            )}
                            {step === '3' && (
                                <ModelingSection />
                            )}
                            {step === '4' && (
                                <VcSection />
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Services;
