import React from 'react';
import { withRouter } from 'react-router-dom';
import Team from "../Team";

const OurTeam = ({ history }) => {
    return (
        <div className="container-fluid bg-our-team py-4 py-md-6">
            <div className="row">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <p className="font-size-6 font-size-md-9 font-weight-bold text-white">OUR TEAM</p>
                            <p className="font-weight-light font-size-4 text-white">NextRound Partners is a group of
                                global entrepreneurs and subject matter experts that each bring their own unique
                                practices, perspectives and approaches to the business world</p>
                        </div>
                    </div>

                    <Team />

                    <div className="row mt-3">
                        <div className="col-12">
                            <p className="text-center mt-5">
                                <button
                                    className="btn btn-primary font-size-4 py-3 px-5"
                                    onClick={() => history.push('/about')}
                                >
                                    LEARN MORE
                                </button>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default withRouter(OurTeam);
