import React from 'react';
import HeaderNav from "./HeaderNav";

const HeaderSection = ({ menu, title, subtitle }) => {
    return (
        <header className="bg-about">
            <HeaderNav menu={menu}/>

            <div className="container d-flex flex-column align-items-center justify-content-center position-relative
                py-5 full-height-home-md-down pt-5 pt-md-7">
                <div className="row">
                    <div className="col-12">
                        <p className="text-white font-size-9 text-center">
                            { title }
                        </p>
                        <p className="text-white font-weight-light font-size-5 text-center">
                            { subtitle }
                        </p>
                    </div>
                </div>
            </div>
        </header>
    );
};

export default HeaderSection;
