import React, {useLayoutEffect, useState} from 'react';
import {Link} from "react-router-dom";

const teamData = [
    {
        id: '1',
        image: 'images/team_ignacio.png',
        title: 'Ignacio Vial',
        link: '/our-team#ignacio'
    },
    {
        id: '2',
        image: 'images/team_alex.png',
        title: 'Alex Crosby',
        link: '/our-team#alex'
    },
    {
        id: '3',
        image: 'images/team_christian.png',
        title: 'Christian Behn',
        link: '/our-team#christian'
    },
    {
        id: '4',
        image: 'images/team_george.png',
        title: 'George Karam',
        link: '/our-team#george'
    },
    {
        id: '5',
        image: 'images/team_join.png',
        title: 'You?',
        link: '/',
    }
]

const Team = ({ color='white' }) => {
    const [loading, setLoading] = useState(false);
    const [team, setTeam] = useState([]);

    useLayoutEffect(() => {
        const _team = [...teamData];

        fetch('https://extreme-ip-lookup.com/json/')
            .then( res => res.json())
            .then(response => {
                if (response.country === 'United States') {
                    const ignacio = _team[0];
                    _team[0] = _team[1];
                    _team[1] = ignacio;
                }
                setTeam(_team);
                setLoading(true);
            })
            .catch(() => {
                setTeam(_team);
                setLoading(true);
            });
    }, []);

    return (
        <div className="row d-flex justify-content-space-evenly mt-5">
            {loading && team.map(t => (
                <div className="col-auto text-center mb-3" key={t.id}>
                    <div className="row m-0">
                        <div className="col-auto p-0">
                            {t.link &&
                                <Link className={"with-border-separated cursor-pointer " + color} to={t.link}>
                                    <img
                                        src={process.env.REACT_APP_ASSETS_URL + t.image}
                                        alt=""
                                        className="img-fluid full-rounded"
                                        width="150"
                                    />
                                </Link>
                            }
                            {!t.link &&
                                <div className={"with-border-separated " + color}>
                                    <img
                                        src={process.env.REACT_APP_ASSETS_URL + t.image}
                                        alt=""
                                        className="img-fluid full-rounded"
                                        width="150"
                                    />
                                </div>
                            }
                        </div>
                    </div>
                    <p className="mt-4">
                        <Link className={"text-"+ color + " font-weight-light text-center"} to={t.link}>
                            {t.title}
                        </Link>
                    </p>
                </div>
            ))}
        </div>
    );
};

export default Team;
