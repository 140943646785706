import React from 'react';

const PrototypingSection = () => {
    return (
        <div className="container-fluid pt-4 pt-md-5">
            <div className="row">
                <div className="container">
                    <div className="row">
                        <div className="col-6">
                            <p className="font-size-5">Build it faster</p>
                            <p className="font-weight-light">
                                We partner with founders looking to convert their idea into a scalable MVP quickly
                                while minimizing technical and human resource friction in that crucial early
                                stage - we shorten time to market so you can focus on building the other areas
                                of your company
                            </p>
                        </div>
                        <div className="col-6">
                            <p className="font-size-5">Simplify the complex processes</p>
                            <p className="font-weight-light">
                                Building a successful tech-enabled business is more than just writing code, it
                                involves the software development process, competitive analysis and a solid go to
                                market strategy - all while working to hit relevant milestones and traction points
                                that will help unlock the capital you need to scale
                            </p>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-12">
                            <p className="text-right font-weight-light mt-5 font-size-4">
                                One hour of time that's worth its weight in gold
                            </p>
                        </div>
                        <div className="col-12">
                            <div className="row">
                                <div className="col-3 border-bottom border-primary ml-auto mr-3"/>
                            </div>
                            <div className="row">
                                <div className="col-12 ml-auto mt-2">
                                    <p className="font-size-4 font-weight-light text-right text-primary">
                                        Rayan F.,
                                        <br/>
                                        Founder & CEO
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PrototypingSection;
