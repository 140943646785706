import React, { useState } from 'react';
import {toast} from 'react-toastify';
import { Modal } from 'react-bootstrap';

import { sendMail } from '../../services/ApiServices';

const ModalForm = ({ show, handleClose }) => {
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [content, setContent] = useState('');

    const sendEmail = () => {
        if (!name || !email || !content) {
            toast.error('All fields are required');
            return;
        }

        sendMail({
            subject: `New message from ${name}`,
            replyTo: email,
            text: `<p><strong>New message sent from nextroundpartners site</strong></p>${content}`,
        });

        setName('');
        setContent('');
        setEmail('');
        handleClose();
        toast.success('Message sent successfully');
    }

    return (
        <>
            <Modal
                size={"md"}
                show={show}
                onHide={handleClose}
                enforceFocus={false}
                dialogClassName="modal-dialog-centered"
            >
                <Modal.Body className="px-4">
                    <div className="row">
                        <div className="col-12 d-flex flex-column py-3">
                            <img src={ process.env.REACT_APP_ASSETS_URL + 'images/nextroundpartners-footer.png'} alt="logo" width="150"/>
                        </div>
                        <div className="col-12 my-3">
                            <div className="form-group">
                                <input
                                    className="form-control bg-light font-size-2 px-3"
                                    type="email"
                                    placeholder="YOUR EMAIL"
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                />
                            </div>

                            <div className="form-group">
                                <input
                                    className="form-control bg-light font-size-2 px-3"
                                    type="text"
                                    placeholder="NAME"
                                    value={name}
                                    onChange={(e) => setName(e.target.value)}
                                />
                            </div>

                            <div className="form-group">
                                <textarea
                                    className="form-control bg-light font-size-2 px-3"
                                    placeholder="HOW CAN WE HELP?"
                                    rows="5"
                                    value={content}
                                    onChange={(e) => setContent(e.target.value)}
                                />
                            </div>
                        </div>
                        <div className="col-12 text-center">
                            <button className="btn btn-primary font-size-3 py-2 px-4" onClick={() => sendEmail()}>
                                SEND
                            </button>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
};

export default ModalForm;
