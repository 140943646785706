import React, {useState} from 'react';
import {Carousel} from "react-bootstrap";

const companyLogosLG = [[
    {
        img: "01 - Bessemer Venture Partners.png",
        link: "https://www.bvp.com/",
    },
    {
        img: "02 - Technology Crossover Ventures.png",
        link: "https://www.tcv.com/",
    },
    {
        img: "03 - Atomico.png",
        link: "https://www.atomico.com/",
    },
    {
        img: "04 - General Catalyst.png",
        link: "https://www.generalcatalyst.com/",
    },
    {
        img: "05 - GV.png",
        link: "https://www.gv.com/",
    },
    {
        img: "06 - Techstars Ventures.png",
        link: "https://www.techstars.com/",
    },
    {
        img: "07 - BLH Ventures.png",
        link: "https://www.blhventures.com/",
    },
    {
        img: "08 - Bain Capital Ventures.png",
        link: "https://www.baincapitalventures.com/",
    },
], [
    {
        img: "09 - Hustle Fund.png",
        link: "https://www.hustlefund.vc/",
    },
    {
        img: "10 - Battery Ventures.png",
        link: "https://www.blhventures.com/",
    },
    {
        img: "11 - Sapphire Ventures.png",
        link: "https://sapphireventures.com/",
    },
    {
        img: "12 - The Fund.png",
        link: "https://thefund.vc/",
    },
    {
        img: "13 - Base10 Partners.png",
        link: "https://base10.vc/",
    },
    {
        img: "14 - Endeavor Catalyst.png",
        link: "https://endeavor.org/approach/catalyst/",
    },
    {
        img: "15 - Foundry Group.png",
        link: "https://www.foundrygroup.com/",
    },
    {
        img: "16 - 500 Startups.png",
        link: "https://500.co/",
    },
], [
    {
        img: "17 - Hyde Park Venture Partners.png",
        link: "https://www.hydeparkvp.com/",
    },
    {
        img: "18 - True Ventures.png",
        link: "https://trueventures.com/",
    },
    {
        img: "19 - Intel Capital.png",
        link: "https://www.intel.com/content/www/us/en/intel-capital/overview.html",
    },
    {
        img: "20 - 360 Capital Partners.png",
        link: "https://www.360cap.vc/",
    },
    {
        img: "21 - OpenView.png",
        link: "https://openviewpartners.com/",
    },
    {
        img: "22 - August Capital.png",
        link: "https://www.augustcap.com/",
    },
    {
        img: "23 - Salesforce Ventures.png",
        link: "https://www.salesforce.com/company/ventures/",
    },
    {
        img: "24 - Dreamit Ventures.png",
        link: "https://www.dreamit.com/",
    },
], [
    {
        img: "25 - QuestMark Partners.png",
        link: "https://www.questm.com/",
    },
    {
        img: "26 - P101.png",
        link: "https://www.p101.it/",
    },
    {
        img: "27 - Alexa Fund.png",
        link: "https://developer.amazon.com/en-US/alexa/alexa-startups/alexa-fund",
    },
    {
        img: "28 - Fontinalis Partners.png",
        link: "https://fontinalis.com/",
    },
    {
        img: "29 - BIP Capital.png",
        link: "https://www.bip-capital.com/",
    },
    {
        img: "30 - Porsche Ventures.png",
        link: "https://www.porsche.ventures/",
    },
    {
        img: "31 - Konvoy Ventures.png",
        link: "https://konvoy.vc/",
    },
    {
        img: "32 - and more.png",
    },
]
];
const companyLogosSM = [[
    {
        img: "01 - Bessemer Venture Partners.png",
        link: "https://www.bvp.com/",
    },
    {
        img: "02 - Technology Crossover Ventures.png",
        link: "https://www.tcv.com/",
    },
    {
        img: "03 - Atomico.png",
        link: "https://www.atomico.com/",
    },
    {
        img: "04 - General Catalyst.png",
        link: "https://www.generalcatalyst.com/",
    },
], [
    {
        img: "05 - GV.png",
        link: "https://www.gv.com/",
    },
    {
        img: "06 - Techstars Ventures.png",
        link: "https://www.techstars.com/",
    },
    {
        img: "07 - BLH Ventures.png",
        link: "https://www.blhventures.com/",
    },
    {
        img: "08 - Bain Capital Ventures.png",
        link: "https://www.baincapitalventures.com/",
    },
], [
    {
        img: "09 - Hustle Fund.png",
        link: "https://www.hustlefund.vc/",
    },
    {
        img: "10 - Battery Ventures.png",
        link: "https://www.blhventures.com/",
    },
    {
        img: "11 - Sapphire Ventures.png",
        link: "https://sapphireventures.com/",
    },
    {
        img: "12 - The Fund.png",
        link: "https://thefund.vc/",
    },
], [
    {
        img: "13 - Base10 Partners.png",
        link: "https://base10.vc/",
    },
    {
        img: "14 - Endeavor Catalyst.png",
        link: "https://endeavor.org/approach/catalyst/",
    },
    {
        img: "15 - Foundry Group.png",
        link: "https://www.foundrygroup.com/",
    },
    {
        img: "16 - 500 Startups.png",
        link: "https://500.co/",
    },
], [
    {
        img: "17 - Hyde Park Venture Partners.png",
        link: "https://www.hydeparkvp.com/",
    },
    {
        img: "18 - True Ventures.png",
        link: "https://trueventures.com/",
    },
    {
        img: "19 - Intel Capital.png",
        link: "https://www.intel.com/content/www/us/en/intel-capital/overview.html",
    },
    {
        img: "20 - 360 Capital Partners.png",
        link: "https://www.360cap.vc/",
    },
], [
    {
        img: "21 - OpenView.png",
        link: "https://openviewpartners.com/",
    },
    {
        img: "22 - August Capital.png",
        link: "https://www.augustcap.com/",
    },
    {
        img: "23 - Salesforce Ventures.png",
        link: "https://www.salesforce.com/company/ventures/",
    },
    {
        img: "24 - Dreamit Ventures.png",
        link: "https://www.dreamit.com/",
    },
], [
    {
        img: "25 - QuestMark Partners.png",
        link: "https://www.questm.com/",
    },
    {
        img: "26 - P101.png",
        link: "https://www.p101.it/",
    },
    {
        img: "27 - Alexa Fund.png",
        link: "https://developer.amazon.com/en-US/alexa/alexa-startups/alexa-fund",
    },
    {
        img: "28 - Fontinalis Partners.png",
        link: "https://fontinalis.com/",
    },
], [
    {
        img: "29 - BIP Capital.png",
        link: "https://www.bip-capital.com/",
    },
    {
        img: "30 - Porsche Ventures.png",
        link: "https://www.porsche.ventures/",
    },
    {
        img: "31 - Konvoy Ventures.png",
        link: "https://konvoy.vc/",
    },
    {
        img: "32 - and more.png",
    },
]
];

const Backed = () => {
    const [companyLogos, setCompanyLogos] = useState(window.innerWidth  < 1024 ? companyLogosSM : companyLogosLG);

    const reportWindowSize = () => {
        if (window.innerWidth < 1024) {
            setCompanyLogos(companyLogosSM);
        } else {
            setCompanyLogos(companyLogosLG);
        }
    }

    window.onresize = reportWindowSize;

    return (
        <div className="container-fluid py-4 py-md-6">
            <div className="row">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <p className="font-size-6 font-size-md-9 font-weight-bold text-primary">
                                OUR FOUNDERS GET BACKED
                            </p>
                            <p className="font-weight-light text-dark mt-2">
                                To the tune of over $500M from some of the best in the business
                            </p>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-12">
                            <Carousel
                                interval={15000}
                                className="company-logos"
                                nextIcon={(
                                    <span>
                                        <i className="icon-next-arrow text-dark"/>
                                    </span>
                                )}
                                prevIcon={(
                                    <span>
                                        <i className="icon-back-arrow text-dark"/>
                                    </span>
                                )}
                            >
                                {companyLogos.map((cl, clIndex) => (
                                   <Carousel.Item key={clIndex}>
                                        <div className="d-flex justify-content-between py-4">
                                            {cl.map((c, cIndex) => (
                                                <div className="position-relative m-3 cursor-pointer" key={cIndex} onClick={() => c.link && window.open(c.link)}>
                                                    <div className="with-border-separated dark">
                                                        <img
                                                            src={process.env.REACT_APP_ASSETS_URL + 'images/company-logos/' + c.img}
                                                            alt=""
                                                            className="img-fluid full-rounded size-100"
                                                        />
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    </Carousel.Item>
                                ))}
                            </Carousel>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Backed;
