import React from 'react';
import Footer from "../components/Footer";
import {Link, withRouter} from "react-router-dom";

const TermsOfUseContainer = () => {
    return (
        <>
            <header>
                <div className="container pt-4">
                    <div className="row">
                        <div className="col-12">
                            <Link to='/'>
                                <img src={ process.env.REACT_APP_ASSETS_URL + 'images/nextroundpartners-footer.png'} alt="logo" width="150"/>
                            </Link>
                        </div>
                    </div>
                </div>
            </header>
            <main role="main">
                <div className="container-fluid text-dark pt-5">
                    <div className="row">
                        <div className="container">
                            <div className="row">
                                <div className="col-12">
                                    <h1 className="text-primary font-size-9">
                                        NEXTROUND PARTNERS <br/>WEBSITE TERMS OF USE
                                    </h1>
                                    <p className="font-size-4 pt-2">Last updated 25 february 2021</p>
                                </div>
                                <div className="col-12 mt-4">
                                    <p>
                                        These are the terms governing your use of this website. To the extent that you have been directed to these terms from another NextRound Partners website that may be hosted on a different domain, please note that these terms will also govern your use of such site. If in doubt as to whether a particular Site is governed by these terms, please contact legal@500startups.com. If you do not agree with these terms you may not use the Site.
                                    </p>
                                    <p>
                                        This Terms of Service sets forth the terms and conditions governing your use of the Site and your access to and use of the information, content and services offered on the Site (collectively with the Site, the “Services”). By accessing or using the Services, you agree to be legally bound by this Agreement, as it may be amended from time to time.
                                    </p>
                                    <p>
                                        NextRound Partners Management Company, L.L.C. (together with its affiliates hereby referred to as “NextRound Partners”) provides investment advisory services only to privately offered NextRound Partners funds and does not solicit nor make their services available to the public or other advisory clients.
                                    </p>
                                    <p>
                                        Note all content provided on this Site is provided for general informational or educational purposes only. NextRound Partners makes no representations as to the accuracy or information contained on any page on this Site. Unless otherwise indicated, the numbers represented on this Site provide a snapshot as at a particular time based on internal estimates which have not been independently verified and may change from time to time.
                                    </p>
                                    <p className="text-primary pt-3">
                                        1. USE OF SITE
                                    </p>
                                    <p>
                                        NextRound Partners has established the Site to provide information about its operations and investment advisory activities.
                                    </p>
                                    <p>
                                        As a condition to your use of the Services, you will not, directly or indirectly: (a) use the Services to infringe the intellectual property rights of others; (b) use the Services or make any attempt to penetrate, modify or manipulate the Services to invade the privacy of any other user of the Services; (c) attempt to modify, erase or damage any information contained on the Site or any device connected to the Services; (d) reverse engineer any portion of the Services; (e) restrict or inhibit others from using the Services; (f) engage in conduct or distribute material that is harmful, obscene, otherwise illegal or objectionable, or gives rise to civil liability; (g) spoof or otherwise impersonate any individual or entity; or (h) violate or attempt to violate the security or integrity of the Services.
                                    </p>
                                    <p>
                                        NextRound Partners reserves the right to investigate and to involve and cooperate with law enforcement authorities, and to pursue a civil lawsuit or criminal prosecution for any alleged or actual harmful or illegal activities involving any of the Services. NextRound Partners reserves the right, at any time, to modify or discontinue, temporarily or permanently, the Services, with or without notice. Further, NextRound Partners reserves the right to modify this Agreement at any time by posting a notice to the Site. You should visit the Site periodically to review the current terms and conditions.
                                    </p>
                                    <p className="text-primary pt-3">
                                        2. INFORMATIONAL PURPOSES ONLY, LEGAL DISCLAIMERS
                                    </p>
                                    <p>
                                        The Site is intended solely to provide information regarding NextRound Partners. The information provided on the Site, including information regarding current and historical clients advised by NextRound Partners, is not intended as a recommendation to purchase, sell or hold any security or that you pursue any investment style or strategy. Under no circumstances is any content or information on the Site intended as, nor should you construe it as legal, tax, accounting, investment or other advice from NextRound Partners. The past performance on any investment, investment style or strategy is not a guarantee of future results, and it should not be assumed that results for any client companies listed on this Site will be achieved for other investments.
                                    </p>
                                    <p>
                                        Where indicated this Site may include content from guest speakers and any views or opinions expressed in such posts are personal and do not represent those of NextRound Partners or any of its staff or affiliates unless explicitly stated.
                                    </p>
                                    <p>
                                        The client companies identified and discussed on this Site may not represent all of the clients advised by NextRound Partners. The companies identified were not selected based on the return on the investment in them by funds advised by NextRound Partners. Such companies were selected as representative examples that clarify and typify NextRound Partners’ service offerings and strategy and not as an exhaustive list of NextRound Partners’ clients. These companies are updated from time to time and the note on the “clients” or other relevant page lists the date of the most recent Site update. Portfolio companies listed on this Site may include a select sampling of clients advised by NextRound Partners as at the date indicated on the relevant page. Subject to relevant confidentiality restrictions, a full list of client companies for a particular fund is available to prospective investors on request. Not every. Case study provided is shown for illustrative purposes as an example of potential ecosystem development or education services that could be provided by NextRound Partners, and should not be considered as an advertisement or solicitation of interest to invest in any other NextRound Partners project or client.
                                    </p>
                                    <p>
                                        Under no circumstances should any information or content on this Site, be considered as an offer to sell or solicitation of interest to purchase any securities advised by NextRound Partners or any of its affiliates or representatives. Further, no content or information on this Site is or is intended as an offer to provide any investment advisory service or financial advice by NextRound Partners. Under no circumstances should anything herein be construed as fund marketing materials by prospective investors considering an investment into any NextRound Partners investment fund. Prospective investors should bear in mind that past results are not necessarily indicative of future results and there can be no assurance any portfolio company of any investment fund advised by NextRound Partners will achieve results comparable to those of any company listed on this Site nor any prior or existing fund advised by NextRound Partners or its principals. Note the intended audience for certain content on this Site is prospective portfolio company founders considering applying to investment funds for funding, to participate in the Seed or Series A Accelerator Programs or potential strategic partners sponsors looking to engage NextRound Partners strategic partnership services. Under no circumstances should any statistics, quotations or other content be interpreted as testimonials or endorsement of the investment performance of any NextRound Partners client by a prospective investor considering an investment into any NextRound Partners client.
                                    </p>
                                    <p>
                                        Any prospective investor should consult with any reliance on his, her or its own personal legal counsel, accountant or other advisors as to the legal, tax, and economic implications of any investment opportunity and its suitability for such investor and NextRound Partners accepts no responsibility for any loss which may arise from reliance on the information on this Site, or any site which is linked from this Site.
                                    </p>
                                    <p>
                                        NextRound Partners LLC. is an investment advisor, reporting to the Securities and Exchange Commission of the United States of America under the Investment Advisers Act of 1940 (as amended) (the “Investment Advisers Act”) as an exempt reporting adviser, solely providing investment advice to venture capital funds. However, such classification as an exempt reporting advisor does not imply a particular level of skill or training. Other NextRound Partners entities operating outside of the United States may or may not be conducting similar activities to NextRound Partners L.L.C. and accordingly, may or may not necessarily have an equivalent regulatory registration or license.
                                    </p>
                                    <p>
                                        While NextRound Partners has taken reasonable steps to ensure that the information contained in these pages is accurate and up-to-date, no liability can be accepted for any error or omissions appearing in this Site. Data presented on this Site is based on internal estimates as the date indicated on the relevant page and has not been independently verified.
                                    </p>
                                    <p>
                                        If you are in any doubt as to the validity of information made available in these pages, or if you wish to rely on this information, then it may be in your best interests to seek verification by contacting us. The pages on this Site are issued in the United States, and comply with appropriate US legislation and regulation. The information provided on the Site is not intended for distribution to, or use in, any jurisdiction where such distribution or use would be contrary to law. You are responsible for compliance with applicable local laws and regulations. We welcome interest and enquiries from users worldwide. However, if you are accessing this Site from outside the US, local legislation and regulation may not permit us to provide services to you in your own country.
                                    </p>
                                    <p>
                                        The vision and strategy strategy outlined herein represents the current strategy of NextRound Partners as at the date indicated on the relevant page, however, actual strategy or specific investment terms may vary at the discretion of NextRound Partners management or the general partner of the relevant fund depending on the investment mandate of the particular fund in question.
                                    </p>
                                    <p>
                                        Note NextRound Partners programs, investor education services, strategic partnership consulting services and events are operated by NextRound Partners L.L.C. and the funds advised by NextRound Partners do not participate in any revenue generated by these activities. Such programs and services are provided for educational and informational purposes only and under no circumstances should any content provided as part of any such programs, services or events be construed as investment, legal, tax or accounting advice by NextRound Partners, L.L.C. or any of its affiliates. NextRound Partners, L.L.C. will under no circumstances provide nor should it be deemed to be providing investment advice as defined under the Investment Advisers Act. Any engagement of consulting services involving the provision of investment advice will not be with NextRound Partners, L.L.C. and will be contracted through an affiliate which is appropriately qualified, and shall also be subject to the end-client satisfying certain regulatory conditions.
                                    </p>
                                    <p>
                                        All logos and trademarks of third parties referenced on this Site are the trademarks and logos of their respective owners. Any inclusion of such trademarks or logos does not imply or constitute any approval, endorsement or sponsorship of NextRound Partners by such owners.
                                    </p>
                                    <p>
                                        Please refer to the “Privacy Policy” for details of how NextRound Partners may use your details. Staff listed represent a snapshot of the current team members as of the date indicated and may be subject to change from time to time. The title “Partner” is used in accordance with customary business practice in the venture capital industry and does not indicate a legal status in a partnership.
                                    </p>
                                    <p>
                                        This Site may also provide a snapshot of members of the NextRound Partners mentor network who provide guidance to our portfolio companies as of the date indicated and may not include all mentors. Note mentors are not employees or independent contractors of NextRound Partners and any views or opinions represented by such mentors are personal and do not represent those of NextRound Partners or any of its personnel or affiliates.
                                    </p>
                                    <p className="text-primary pt-3">
                                        3. OWNERSHIP
                                    </p>
                                    <p>
                                        All content, materials, and software included in the Services are the property of NextRound Partners, and are protected by U.S. and international copyright, trademark and other intellectual property laws. You may download and retain one copy of this Site only for your own records and personal use. No commercial use, resale, copying or exploitation of material from the Services is permitted without the express written permission of NextRound Partners and any other applicable copyright owner. All rights not expressly granted hereunder are expressly reserved to NextRound Partners. The Copyright Notice and Procedures are incorporated into this Agreement by this reference.
                                    </p>
                                    <p>
                                        When creating products or materials using any of our marks or logos, please put the following tagline at the bottom of the page/ad/etc.: © 2018 NextRound Partners LLC All rights reserved. NextRound Partners™ and the NextRound Partners Logo are trademarks of NextRound Partners LLC.
                                    </p>
                                    <p>
                                        Each of the marks below have a corresponding logo which is also owned by NextRound Partners NextRound Partners™
                                    </p>
                                    <p className="text-primary pt-3">
                                        4. THIRD-PARTY SITES
                                    </p>
                                    <p>
                                        The Site may link to third-party sites. These links are provided for your convenience, and NextRound Partners assumes no responsibility whatsoever for any content made available at any third party site. Any privacy policies of any third-party sites are governed by the operator of such site. NextRound Partners urges you to read the relevant legal terms and privacy policy (if any) of these third-party sites.
                                    </p>
                                    <p className="font-weight-bold pt-3">
                                        WARRANTIES/LIMITATION OF LIABILITY
                                    </p>
                                    <p>
                                        The Services are provided “as is” and “as available” without warranties of any kind, express, implied, or statutory, including, without limitation, any implied warranties of merchantability, fitness for a particular purpose, non-infringement, and title. NextRound Partners does not warrant the accuracy of the information contained on the Site or that the Services will be uninterrupted or error-free. NextRound Partners nor their affiliates, subsidiaries, licensors, or third-party service providers shall be liable for any damages of any kind arising out of your use of the Services, even if NextRound Partners has been advised of the possibility of such damages. Some jurisdictions do not allow these limitations, so the foregoing may not apply to you. To the extent the foregoing limitation of liability is held to be unenforceable, the aggregate liability of NextRound Partners for any cause of action arising out of your use of the Services shall be limited to direct damages actually incurred up to $250.
                                    </p>
                                    <p className="font-weight-bold pt-3">
                                        INDEMNITY OF NextRound Partners
                                    </p>
                                    <p>
                                        You agree to indemnify and hold NextRound Partners, its respective subsidiaries, affiliates, and third-party service providers harmless from any claim, demand, cause of action, debt, loss or liability, including reasonable attorneys’ fees, to the extent that such action is based upon, arises out of, or relates to a breach of this Agreement or your use of the Services. This indemnity shall survive the termination of this Agreement.
                                    </p>
                                    <p className="font-weight-bold pt-3">
                                        CHOICE OF LAW AND FORUM
                                    </p>
                                    <p>
                                        The substantive laws of the State of California govern any disputes arising under this Agreement and your use of the Services, without regard to principles of conflict of laws. You agree to submit to the exclusive jurisdiction of the appropriate state and federal courts in the State of California with respect to claims you may have under this Agreement.
                                    </p>
                                    <p className="font-weight-bold pt-3">
                                        MISCELLANEOUS TERMS
                                    </p>
                                    <p>
                                        This Agreement constitutes the entire agreement between you and NextRound Partners with respect to its subject matter, superseding any prior agreements between you and NextRound Partners relating to such subject matter. The failure of NextRound Partners to exercise or enforce any right or provision of this Agreement shall not constitute a waiver of such right or provision. If a court of competent jurisdiction finds any provision of this Agreement to be invalid, the court should endeavor to give effect to the parties’ intentions, and the other provisions of this Agreement shall remain in full force and effect. The section headings used in this Agreement are for convenience only and have no legal or contractual effect.
                                    </p>
                                    <p>
                                        If you have any questions about this Agreement, the Site or the Services, please contact NextRound Partners in the United States at <a className="text-dark text-decoration-underline" href="mailto:hello@nextroundpartners.com">hello@nextroundpartners.com</a>
                                    </p>
                                    <p>
                                        Effective February 25, 2021. NextRound Partners may make changes to this Agreement in the future. Last updated February, 2021.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>

            <Footer />
        </>
    );
};

export default withRouter(TermsOfUseContainer);
