import React, {useState} from 'react';

const ScrollArrow = () =>{

    const [showScroll, setShowScroll] = useState(false)

    const checkScrollTop = () => {
        if (!showScroll && window.pageYOffset > 400){
            setShowScroll(true)
        } else if (showScroll && window.pageYOffset <= 400){
            setShowScroll(false)
        }
    };

    const scrollTop = () =>{
        window.scrollTo({top: 0, behavior: 'smooth'});
    };

    window.addEventListener('scroll', checkScrollTop)

    return (
        showScroll ? (
            <img
                className="scrollTop-btn"
                onClick={scrollTop}
                alt="Back to top"
                src={process.env.REACT_APP_ASSETS_URL + 'images/back-to-top.png'}
            />
        ) : null
    );
}

export default ScrollArrow;
