import React from 'react';

const FractionalSection = () => {
    return (
        <div className="container-fluid pt-4 pt-md-5">
            <div className="row">
                <div className="container">
                    <div className="row">
                        <div className="col-6">
                            <p className="font-size-5">Not your traditional CFO</p>
                            <p className="font-weight-light">
                                Traditional CFO’s are great… for traditional mature companies. For fast growing
                                tech-enabled companies, founders need to be able to see their finances and company in a
                                different light, not through historical GAAP reporting.. but through angles that help
                                them understand the unique levers to their business. For top tier founders, forecasting
                                isn’t as simple as projecting 10% revenue growth quarter over quarter. They need the
                                ability to highlight key KPIs and showcase repeatability in their model. That’s
                                where NextRound comes in
                            </p>
                        </div>
                        <div className="col-6">
                            <p className="font-size-5">Experienced team</p>
                            <p className="font-weight-light">
                                Our founders are armed with a strategic partner and custom tools that reveal key
                                business insights and metrics that improve their ability to lead. We help them own
                                their strategy, board meetings and company’s future. We give our founders the ability
                                to tell their business’s story to stakeholders in a way that builds consensus and
                                buy-in so that they can get to that next round of capital
                            </p>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <p className="text-right font-weight-light mt-5 font-size-4">
                                The model introduced by NextRound Partners was super robust and quickly investor-ready.
                                I can't imagine having to go through my investor meetings without it. The Team took the
                                time to really understand my business and I feel like
                                <strong>we really are partners.</strong> Can't recommend highly enough!
                            </p>
                        </div>
                        <div className="col-12">
                            <div className="row">
                                <div className="col-3 border-bottom border-primary ml-auto mr-3"/>
                            </div>
                            <div className="row">
                                <div className="col-12 ml-auto mt-2">
                                    <p className="font-size-4 font-weight-light text-right text-primary">
                                        Cameron H,
                                        <br/>
                                        Founder & CEO
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default FractionalSection;
