import React from 'react';
import {Link, withRouter} from "react-router-dom";

const Footer = () => {
    return (
        <footer className="container py-5">
            <div className="row">
                <div className="col-12 mb-4 mb-md-0 order-0 text-center text-md-left">
                    <Link to='/'>
                        <img
                            src={ process.env.REACT_APP_ASSETS_URL + 'images/nextroundpartners-footer.png'}
                            className="img-fluid mb-3"
                            alt="logo"
                            width="200"
                        />
                    </Link>
                </div>
                <div className="col-12 col-md-auto mr-md-5 order-2 order-md-1">
                    <ul className="list-unstyled text-small text-center text-md-left">
                        <li className="text-muted font-weight-light">Austin, TX</li>
                        <li className="text-muted font-weight-light">Barcelona, Spain</li>
                        <li className="text-muted font-weight-light">Amsterdam, Netherlands</li>
                        <li className="text-muted font-weight-bold mt-2">©NextRound Partners 2021</li>
                    </ul>
                </div>
                <div className="col-12 col-md order-1 order-md-2">
                    <ul className="list-unstyled text-small text-center text-md-left">
                        <li className="text-muted font-weight-light cursor-pointer" onClick={() => window.open(
                            'https://forms.monday.com/forms/95170405d990b3f8354d7f6533d11765?r=use1'
                        )}>Jobs</li>
                        <li className="text-muted font-weight-light">
                            <Link to={'/privacy-policy'} className="text-muted">
                                Privacy Policy
                            </Link>
                        </li>
                        <li className="text-muted font-weight-light">
                            <Link to={'/term-of-use'} className="text-muted">
                                Terms of Use
                            </Link>
                        </li>
                        <li className="text-muted font-weight-bold mt-2">
                            <a
                                href="mailto:hello@nextroundpartners.com"
                                className="nav-link p-0 text-dark"
                            >
                                hello@nextroundpartners.com
                            </a>
                    </li>
                    </ul>
                </div>
            </div>
        </footer>
    );
};

export default withRouter(Footer);
