import React from 'react';

const GlobalFootprint = () => {
    return (
        <div className="container-fluid py-4 py-md-6">
            <div className="row">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <p className="font-size-6 font-size-md-9 font-weight-bold text-primary">
                                GLOBAL FOOTPRINT
                            </p>
                            <p className="font-weight-light text-dark mt-2">
                                We're a diverse, distributed team spanning 9 timezones, representing 7 countries and
                                with extensive experience scaling projects in multitudes of sectors across the globe
                            </p>
                        </div>
                    </div>

                    <div className="col-12">
                        <img
                            src={process.env.REACT_APP_ASSETS_URL + 'images/map-people.png'}
                            alt="logo"
                            className="img-fluid"
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default GlobalFootprint;
