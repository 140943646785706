import React from 'react';
import { Modal } from 'react-bootstrap';

const ModalImage = ({ show, handleClose, imgSrc }) => {
    return (
        <>
            <Modal
                size={"lg"}
                show={show}
                onHide={handleClose}
                enforceFocus={false}
                dialogClassName="modal-dialog-centered"
            >
                <Modal.Body className="px-4">
                    <div className="row">
                        <div className="col-12 px-2">
                            <img src={imgSrc} alt="" className="img-fluid"/>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
};

export default ModalImage;
