import React, { useEffect } from 'react';

import Footer from "../components/Footer";
import ContactUs from "../components/home/ContactUs";
import OurTeam from "../components/home/OurTeam";
import Operate from "../components/home/Operate";
import Services from "../components/home/Services";
import Testimonials from "../components/home/Testimonials";
import Backed from "../components/home/Backed";
import MainHeader from "../components/headers/MainHeader";

const HomeContainer = ({ location }) => {
    const scrollIntoViewProps = { behavior: 'smooth', block: 'start' };

    const aboutUsRef = React.createRef();
    const executeScrollToAboutUs = () => aboutUsRef.current.scrollIntoView(scrollIntoViewProps);

    const contactUsRef = React.createRef();
    const executeScrollToContactUs = () => contactUsRef.current.scrollIntoView(scrollIntoViewProps);

    const servicesRef = React.createRef();
    const executeScrollToServices = () => servicesRef.current.scrollIntoView(scrollIntoViewProps);

    const ourTeamRef = React.createRef();
    const executeScrollToOurTeam = () => ourTeamRef.current.scrollIntoView(scrollIntoViewProps);

    useEffect(() => {
        if (location.hash === '#about') {
            executeScrollToAboutUs();
        } else if (location.hash === '#contact-us') {
            executeScrollToContactUs();
        } else if (location.hash === '#services') {
            executeScrollToServices();
        } else if (location.hash === '#our-team') {
            executeScrollToOurTeam();
        } else {
            window.scrollTo({top: 0, behavior: 'smooth'});
        }
    });

    return (
        <>
            <MainHeader />

            <main role="main">
                <div ref={aboutUsRef}>
                    <Backed />
                </div>
                <Testimonials />
                <div ref={servicesRef}>
                    <Services />
                </div>
                <Operate />
                <div ref={ourTeamRef}>
                    <OurTeam />
                </div>
                <div ref={contactUsRef}>
                    <ContactUs />
                </div>
            </main>

            <Footer />
        </>
    );
};

export default HomeContainer;
