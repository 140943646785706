import React, { useState } from 'react';

const operateData = {
    1: {
        imageSrc: 'images/map-clients.png',
        description: "We've worked with founders across multiple continents and partner with leading funds, " +
            "global entrepreneurial accelerators, and ecosystems to support each founder's growth strategy"
    },
    2: {
        imageSrc: 'images/map-people.png',
        description: "We're a diverse, distributed team spanning 9  timezones, representing 7 countries and with " +
            "extensive experience scaling projects in multitudes of sectors across the globe"
    }
}

const Operate = () => {
    const [page, setPage] = useState(1);

    const changePage = (page) => {
        setPage(page);
    }

    return (
        <div className="container-fluid pb-4 pb-md-5">
            <div className="row">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <p className="font-size-6 font-size-md-9 font-weight-bold text-dark">WE OPERATE GLOBALLY</p>
                        </div>
                        <div className="col-12">
                            <div className="row pb-5">
                                <div className="col-12 col-md-4 d-flex flex-column justify-content-end">
                                    <div className="row">
                                        <div className="col-12 d-flex justify-content-around">
                                            <p
                                                className={
                                                    'w-100 text-center border-bottom pb-2 cursor-pointer ' + (
                                                        page === 1 ? 'border-primary text-primary' : ''
                                                    )
                                                }
                                                onClick={() => changePage(1)}
                                            >
                                                OUR FOUNDERS
                                            </p>
                                            <p
                                                className={
                                                    'w-100 text-center border-bottom pb-2 cursor-pointer ' + (
                                                        page === 2 ? 'border-primary' : ''
                                                    )
                                                }
                                                onClick={() => changePage(2)}
                                            >
                                                OUR PEOPLE
                                            </p>
                                        </div>
                                        <div className="col-12">
                                            <p className="font-weight-light text-dark mt-md-5">
                                                {operateData[page].description}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col">
                                    <img
                                        src={process.env.REACT_APP_ASSETS_URL + operateData[page].imageSrc}
                                        alt="logo"
                                        className="img-fluid"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Operate;
