import React from 'react';

const WorkshopsSection = () => {
    return (
        <div className="container-fluid pt-4 pt-md-5">
            <div className="row">
                <div className="container">
                    <div className="row">
                        <div className="col-6">
                            <p className="font-size-5">Thoughtful, conversational & actionable</p>
                            <p className="font-weight-light">
                                We pull back the cloak on building financial models, how they are viewed by investors,
                                and how to use a model to build consensus from leadership. During our workshops we use
                                the same methodology we use for everyone of our clients globally
                            </p>
                        </div>
                        <div className="col-6">
                            <p className="font-size-5">Experienced team</p>
                            <p className="font-weight-light">
                                We are fortunate to be able to work with and mentor some of the world’s best startups
                                that are backed by organizations Techstars, 500vc, Plug and Play, and Endeavor. We
                                routinely hold workshops for organizations globally and love to share our insights,
                                resources and methodology when we can
                            </p>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-12">
                            <p className="text-right font-weight-light mt-5 font-size-4">
                                <strong>Positively overwhelmed</strong> by the content. It gave me different
                                perspective on how to think about raising my next round and talking to VCs
                            </p>
                        </div>
                        <div className="col-12">
                            <div className="row">
                                <div className="col-3 border-bottom border-primary ml-auto mr-3"/>
                            </div>
                            <div className="row">
                                <div className="col-12 ml-auto mt-2">
                                    <p className="font-size-4 font-weight-light text-right text-primary">
                                        Teddy P.,
                                        <br/>
                                        Founder & CEO
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default WorkshopsSection;
