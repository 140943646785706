import React  from 'react';
import {Link} from "react-router-dom";

const CardServices = () => {
    return (
        <div className="row my-5">
            <div className="col-12">
                <div className="row m-0 justify-content-around">
                    <Link to="/services#fractional" className="col-5 col-md services-cards card-01">
                        Fractional CFO
                    </Link>
                    <Link to="/services#fundraising" className="col-5 col-md services-cards card-02">
                        Fundraising Strategy
                    </Link>
                    <Link to="/services#pitch" className="col-5 col-md services-cards card-03">
                        Pitch Prep & Fundraising Narrative
                    </Link>
                    <Link to="/services#workshops" className="col-5 col-md services-cards card-04">
                        Workshops
                    </Link>
                    <Link to="/services#modeling" className="col-5 col-md services-cards card-05">
                        Modeling & Scenario Analysis
                    </Link>
                    <Link to="/services#vc" className="col-5 col-md services-cards card-06">
                        VC Portfolio Support
                    </Link>
                    <Link to="/services#prototyping" className="col-5 col-md services-cards card-07">
                        Prototyping & MVP Development
                    </Link>
                </div>
            </div>
        </div>
    );
};

export default CardServices;
