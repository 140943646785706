import React, {useEffect}  from 'react';

import HeaderSection from "../components/headers/HeaderSection";
import Footer from "../components/Footer";
import FooterSection from "../components/sections/FooterSection";
import FractionalSection from "../components/sections/FractionalSection";
import PitchSection from "../components/sections/PitchSection";
import WorkshopsSection from "../components/sections/WorkshopsSection";
import ModelingSection from "../components/sections/ModelingSection";
import VcSection from "../components/sections/VCSection";
import PrototypingSection from "../components/sections/PrototypingSection";
import FundraisingSection from "../components/sections/FundraisingSection";

const headersTitles = {
    '#fractional': {
        title: "FRACTIONAL CFO SUPPORT",
        subtitle: "We work with VCs across the globe to provide our founders with critical insight about where they need to be",
    },
    '#fundraising': {
        title: "FUNDRAISING STRATEGY",
        subtitle: "We give founders a trusted partner that has their back",
    },
    '#pitch': {
        title: "PITCH PREP & FUNDRAISING NARRATIVE",
        subtitle: "At NRP we equate a company’s value statements to the founder’s children",
    },
    '#workshops': {
        title: "WORKSHOPS",
        subtitle: "We know whats it’s like to be in your shoes and we are ready to share that knowledge",
    },
    '#modeling': {
        title: "MODELING & SCENARIO ANALYSIS",
        subtitle: "We help founders understand why financial modeling is important and where to focus",
    },
    '#vc': {
        title: "VC PORTFOLIO SUPPORT",
        subtitle: "We help to scale your portfolio companies' ability to execute by scaling the leadership teams " +
            "time and in preparation for their next milestones.",
    },
    '#prototyping': {
        title: "PROTOTYPING & MVP DEVELOPMENT",
        subtitle: "Bring your idea to life",
    },
}
headersTitles[''] = {...headersTitles["#fractional"]}

const ServicesContainer = ({ location }) => {

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [location]);

    const getContentSection = (hash) => {
        if (hash === '#fundraising') {
            return (<FundraisingSection />)
        } else if (hash === '#pitch') {
            return (<PitchSection />)
        } else if (hash === '#workshops') {
            return (<WorkshopsSection />)
        } else if (hash === '#modeling') {
            return (<ModelingSection />)
        } else if (hash === '#vc') {
            return (<VcSection />)
        } else if (hash === '#prototyping') {
            return (<PrototypingSection />)
        }

        return (<FractionalSection />)
    }

    return (
        <>
            <HeaderSection
                menu="services"
                title={headersTitles[location.hash].title}
                subtitle={headersTitles[location.hash].subtitle}
            />

            <main role="main">
                { getContentSection(location.hash) }

                <FooterSection />
            </main>

            <Footer />
        </>
    );
};

export default ServicesContainer;
