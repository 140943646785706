import React from 'react';
import {Carousel} from "react-bootstrap";

const testimonialsData = [
    {
        phrase: "NextRound Partners really stands for what it name says: a partner that prepares and accompanies you " +
            "on a journey that has highlights but also a lot of lowlights. I would (and already did) recommend " +
            "Nextround partners to every CEO, especially to first-time founders!",
        phraseAuthor: 'Falk',
        phrasePosition: 'Founder & CEO',
        title: "WE KNOW WHAT IT’S LIKE TO BE IN YOUR SHOES",
        description: "We are former founders who have taken companies from inception through M&A and have since " +
            "worked with hundreds of venture backed companies on their journey to their next round of financing<br /><br /> We " +
            "work side by side with funds to get real time feedback & insights that can help our founders make " +
            "strategic decisions about their future<br /><br /> We love every part of the experience especially unlocking " +
            "founders' ability to tell their business's story through numbers to both internal and " +
            "external stakeholders"
    },{
        phrase: "Engaging with NRP was simple and made my life as a CEO so much easier, quickly scaling my abilities " +
            "and understanding of areas that are not my strong suit. They saved me time and headaches.",
        phraseAuthor: 'Lynn',
        phrasePosition: 'Founder & CEO',
        title: "UNDERSTAND THE PROCESS AND SAVE TIME",
        description: "We augment founders' time with A level talent that can deliver extremely fast at a fraction " +
            "of the price and time it takes for a full time hire.<br /><br /> We guide founders through complex " +
            "and high friction processes acting as their private sherpas. Our job is to make their life easier. " +
            "Providing them with the right materials and strategies while quickly scaling their fundraising " +
            "abilities. Keeping founders and their leadership teams focused on what matters most to us."
    },{
        phrase: "The team at NRP has become a trusted partner and friend over the years. They took the guesswork " +
            "out of fundraising and gave me the confidence and ability to go out and execute.",
        phraseAuthor: 'Matt G',
        phrasePosition: 'Founder & CEO',
        title: "DELIVER YOUR STORY & BUILD AUTHENTIC RELATIONSHIPS",
        description: "We help founders to revamp their fundraising narrative, creating a compelling 'why now' for " +
            "investors that highlights key differentiators and separates them from the rest of the pack."
    }
]

const Testimonials = () => {
    return (
        <div className="container-fluid bg-testimonials text-white pb-4 pt-4 pt-md-6">
            <div className="row">
                <div className="container">
                    <Carousel
                        interval={15000}
                        className="testimonials"
                        nextIcon={(
                            <span>
                                <i className="icon-next-arrow text-light"/>
                            </span>
                        )}
                        prevIcon={(
                            <span>
                                <i className="icon-back-arrow text-light"/>
                            </span>
                        )}
                    >
                        {testimonialsData.map((t, index) => (
                            <Carousel.Item key={index}>
                                <div className="row mt-4 mb-5">
                                    <div className="col-10 ml-auto">
                                        <p className="font-italic font-size-4 font-weight-light text-right">
                                            {t.phrase}
                                        </p>
                                    </div>
                                    <div className="col-12">
                                        <div className="row">
                                            <div className="col-6 border-bottom border-primary ml-auto mr-3"/>
                                        </div>
                                        <div className="row">
                                            <div className="col-12 ml-auto mt-2">
                                                <p className="font-size-4 font-weight-light text-right text-primary">
                                                    {t.phraseAuthor}
                                                    <br/>
                                                    {t.phrasePosition}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row pt-5">
                                    <div className="col-12 col-md-7">
                                        <p className="font-size-6 font-size-md-9 font-weight-bold">
                                            {t.title}
                                        </p>
                                    </div>
                                    <div className="col-12 mt-4">
                                        <div className="row">
                                            <div className="col-12 col-md-10 font-size-4 font-weight-light">
                                                <p
                                                    dangerouslySetInnerHTML={{
                                                        __html: t.description
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Carousel.Item>
                        ))}
                    </Carousel>
                </div>
            </div>
        </div>
    );
};

export default Testimonials;
