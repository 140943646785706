import React  from 'react';
import { Route } from 'react-router-dom';
import { BrowserRouter as Router } from 'react-router-dom';

import HomeContainer from "containers/HomeContainer";
import AboutContainer from "containers/AboutContainer";
import ServicesContainer from "containers/ServicesContainer";
import OurTeamContainer from "./containers/OurTeamContainer";
import ScrollToTop from "./components/base/ScrollToTop";
import ScrollArrow from "./components/ScrollArrow";
import TermsOfUseContainer from "./containers/TermsOfUseContainer";
import PrivacyPolicyContainer from "./containers/PrivacyPolicyContainer";

const App = () => {
    return (
        <Router>
            <ScrollToTop />
            <Route exact path="/" component={HomeContainer} />
            <Route exact path="/about" component={AboutContainer} />
            <Route exact path="/services" component={ServicesContainer} />
            <Route exact path="/our-team" component={OurTeamContainer} />
            <Route exact path="/term-of-use" component={TermsOfUseContainer} />
            <Route exact path="/privacy-policy" component={PrivacyPolicyContainer} />
            <ScrollArrow />
        </Router>
    );
}

export default App;
