import React, { useState } from 'react';
import CardServices from "../CardServices";
import ModalForm from "../modals/ModalForm";

const FooterSection = () => {
    const [show, setShow] = useState(false);

    return (
        <div className="container-fluid py-4 py-md-5">
            <div className="row">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <p className="text-center">
                                <button className="btn btn-primary font-size-4 py-3 px-5" onClick={() => setShow(true)}>
                                    SCHEDULE A CALL
                                </button>
                            </p>
                        </div>
                    </div>
                    <CardServices />
                </div>
            </div>

            <ModalForm show={show} handleClose={() => setShow(false)}/>
        </div>
    );
};

export default FooterSection;
