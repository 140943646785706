import React from 'react';

const FundraisingSection = () => {
    return (
        <div className="container-fluid pt-4 pt-md-5">
            <div className="row">
                <div className="container">
                    <div className="row">
                        <div className="col-6">
                            <p className="font-size-5">The game is stacked against founders</p>
                            <p className="font-weight-light">
                                There are only so many hours in the day and most founders only go through the
                                fundraising process a few times in their entire lives. We play the role of a sherpa
                                throughout the process. Helping them save time, execute quicker, and navigate the
                                fundraising trail with confidence
                            </p>

                            <p className="font-size-5">We're in tune with the latest trends</p>
                            <p className="font-weight-light">
                                We  keep track of market environment and regulations of the venture capital industry
                                globally. We use this knowledge to build and apply customized financial management
                                plans and fundraising strategies to make the most efficient use of your company's
                                financial resources
                            </p>
                        </div>
                        <div className="col-6">
                            <p className="font-size-5">We take the stress out of fundraising</p>
                            <p className="font-weight-light">
                                We find it important for our founders to be able to focus on building genuine
                                relationships with VCs in ways that build brand parity, increase valuations and
                                decrease timelines
                            </p>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-12">
                            <p className="text-right font-weight-light mt-5 font-size-4">
                                Our startup is entering it's beta, and NextRound Partners helped us identify crucial
                                metrics to incorporate into our strategy. We are heading into
                                the <strong>next round of</strong> fundraising more confident in our financials
                                and with a clearer definition of <strong>"success"</strong>
                            </p>
                        </div>
                        <div className="col-12">
                            <div className="row">
                                <div className="col-3 border-bottom border-primary ml-auto mr-3"/>
                            </div>
                            <div className="row">
                                <div className="col-12 ml-auto mt-2">
                                    <p className="font-size-4 font-weight-light text-right text-primary">
                                        Dara C,
                                        <br/>
                                        Founder & CEO
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default FundraisingSection;
