import React from 'react';
import HeaderNav from "./HeaderNav";

const HeaderTeams = ({ menu, userName, location, photo, bgClass, link }) => {
    return (
        <>
            <header className={bgClass}>
                <HeaderNav menu={menu}/>

                <div className="container d-flex flex-column align-items-center align-items-md-start
                                justify-content-center position-relative py-5 pt-7">
                    <div className="row position-absolute position-md-relative">
                        <div className="col-auto col-md-3 mx-auto mt-6 mt-md-0 mb-md-0">
                            <div className="row">
                                <div className="col-12 p-0">
                                    <div className="with-border-separated">
                                        <img
                                            src={process.env.REACT_APP_ASSETS_URL + photo}
                                            alt=""
                                            className="img-fluid full-rounded img-profile-header"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-md-auto pl-md-2 d-none d-md-block">
                            <span className="text-white font-size-9 text-center text-md-left">
                                { userName }
                            </span>
                            <br/>
                            <span className="text-white font-size-9 text-center text-md-left position-relative">
                                { location }
                                <img
                                    src={process.env.REACT_APP_ASSETS_URL + 'images/linkedin_circle_color.svg'}
                                    alt="logo"
                                    className="img-fluid icon-linkedin cursor-pointer"
                                    onClick={() => window.open(link)}
                                />
                            </span>
                        </div>
                    </div>
                </div>
            </header>
            <div className="container mt-7 d-md-none">
                <div className="row">
                    <div className="col-12 d-flex justify-content-center">
                        <span className="text-dark font-size-5 font-weight-bold">
                            { userName }, { location }
                        </span>
                        <img
                            src={process.env.REACT_APP_ASSETS_URL + 'images/linkedin_circle_color.svg'}
                            alt="logo"
                            className="img-fluid icon-linkedin cursor-pointer"
                            onClick={() => window.open(link)}
                        />
                    </div>
                </div>
            </div>
        </>
    );
};

export default HeaderTeams;
