import React, { useState } from 'react';
import ModalForm from "../modals/ModalForm";

const ContactUs = () => {
    const [show, setShow] = useState(false);
    return (
        <div className="container-fluid text-dark py-4 py-md-6">
            <div className="row">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <p className="font-size-6 font-size-md-9 text-primary mb-4">CONTACT US</p>
                            <p className="font-size-3 font-weight-light"><strong>Our mission</strong> is to be the help
                                that we wish we had during our previous ventures. Helping founders pave their own way
                                in the ecosystem while implementing innovative approaches and getting off the
                                beaten path in this <strong>adventure</strong></p>
                            <p className="text-center mt-5">
                                <button className="btn btn-primary font-size-4 py-3 px-5" onClick={() => setShow(true)}>
                                    SCHEDULE A CALL
                                </button>
                            </p>
                        </div>
                    </div>
                </div>
            </div>

            <ModalForm show={show} handleClose={() => setShow(false)}/>
        </div>
    );
};

export default ContactUs;
